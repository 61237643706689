<template>
    <div class="web-app-home">
        <div class="tab-bar-user-panel">
            <div class="container">
                <memod-list-tab-bar
                    :current-list="$route.name"
                    :tabs-list="homeTabBarList"
                />
            </div>
        </div>
        <div class="container">
            <keep-alive>
                <router-view :list-endpoint="currentListTab.endpoint" />
            </keep-alive>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "WebAppHome",
    components: {
        MemodListTabBar: () => import(/* webpackChunkName: "home-tab-bar" */ "@/components/organisms/memod-list-tab-bar")
    },
    data() {
        return {
            homeTabBarList: [
                {
                    name: "web-app-home-following",
                    label: "Following",
                    endpoint: "/feeds?type=following"
                },
                {
                    name: "web-app-home-notable",
                    label: "Notable",
                    endpoint: "/feeds?type=for-you"
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        ...mapState({
            userData: state => state.User.data
        }),
        currentListTab() {
            return this.homeTabBarList.find(tab => tab.name == this.$route.name);
        }
    }
}
</script>

<style lang="scss" scoped>
.web-app-home {
    @media (min-width: 1200px) {
        .container {
            max-width: 1440px;
        }
    }

    .tab-bar-user-panel {
        background-color: #0F0F10;
        width: 100%;
        top: 0;
        z-index: 10;
        border-bottom: 1px solid #4b4b4b;

        .container {
            display: flex;

            @media (min-width: 1200px) {
                max-width: 1220px;
            }

            .memod-list-tab-bar {
                border: 0;
            }

            .user-link {
                a {
                    margin-top: 19px;
                    margin-bottom: 19px;
                    margin-left: auto;
                    color: white;
                    font-weight: 600;
                    font-size: 16px;
                    text-decoration: none;
                    align-self: center;
                    width: 44px;
                    height: 44px;
                    background-color: black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    cursor: pointer;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
