<template>
    <div class="web-app-search" :class="{ 'is-mobile' : isMobileScreen }">
        <div class="tab-bar-user-panel">
            <div class="search-edit">
                <div class="container">
                    <search-bar :is-static-search="!isMobileScreen" :is-mobile="isMobileScreen" />
                </div>
            </div>
            <div class="container">
                <memod-list-tab-bar
                    v-if="$route.params.searchString"
                    :current-list="this.$route.name"
                    :tabs-list="searchTabBarList"
                />
            </div>
        </div>
        <div class="container">
            <router-view :list-endpoint="currentListTab.endpoint" />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "WebAppSearch",
    components: {
        MemodListTabBar: () => import(/* webpackChunkName: "home-tab-bar" */ "@/components/organisms/memod-list-tab-bar"),
        SearchBar: () => import(/* webpackChucnkName: "search-bar" */ "@/components/organisms/search-bar/")
    },
    data() {
        return {
            searchTabBarList: []
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        ...mapState({
            userData: state => state.User.data,
            isMobileScreen: state => state.Application.isMobileScreen
        }),
        currentListTab() {
            return this.searchTabBarList.find(tab => tab.name == this.$route.name);
        }
    },
    watch: {
        "$route.params.searchString": {
            immediate: true,
            handler(newSearchString) {
                this.setSearchTabList(newSearchString);
            }
        }
    },
    mounted() {
        if (!this.$route.params.searchString) {
            setTimeout(() => {
                this.$nextTick(() => {
                    const element = document.querySelector(".search-input input");
                    element.focus();
                })
            }, 250)
        }
    },
    updated() {
        window.scrollTo(0, 0);
    },
    methods: {
        setSearchTabList(searchString) {
            const searchStringEncoded = encodeURIComponent(searchString);
            this.searchTabBarList = [];
            this.searchTabBarList = [
                {
                    name: "web-app-search-memos",
                    label: "Memos",
                    endpoint: `/search?q=${searchStringEncoded}`
                },
                {
                    name: "web-app-search-creators",
                    label: "Creators",
                    endpoint: `/search?q=${searchStringEncoded}&index=users`
                },
                {
                    name: "web-app-search-topics",
                    label: "Topics",
                    endpoint: `/search?q=${searchStringEncoded}&index=topics`
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.web-app-search {
    height: 100%;
    @media (min-width: 1200px) {
        .container {
            max-width: 1440px;
        }
    }

    .search-edit {
        border-bottom: 1px solid #4b4b4b;
        padding-top: 50px;
        padding-bottom: 25px;
        position: relative;

        /deep/ .search-bar {
            .search-input {
                input {
                    width: 100%;
                    border: 0;
                }
            }
        }
    }

    .tab-bar-user-panel {
        background-color: #0F0F10;
        width: 100%;
        top: 0;
        z-index: 10;
        border-bottom: 1px solid #4b4b4b;

        .container {
            display: flex;

            @media (min-width: 1200px) {
                max-width: 1220px;
            }

            .memod-list-tab-bar {
                border: 0;
            }

            .user-link {
                a {
                    margin-top: 19px;
                    margin-bottom: 19px;
                    margin-left: auto;
                    color: white;
                    font-weight: 400;
                    font-size: 16px;
                    text-decoration: none;
                    align-self: center;
                    width: 44px;
                    height: 44px;
                    background-color: black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    cursor: pointer;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    /deep/ .memod-list-tab-bar {
        .links {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    &.is-mobile {
        .search-edit {
            border-bottom: 0;
            z-index: 10;
            padding-bottom: 0;
        }

        /deep/ .loader {
            z-index: 0;
        }

        .tab-bar-user-panel {
            border-bottom: 0;

            /deep/ .links {
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
    }
}
</style>
